
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'

export default defineComponent({
  components: {
    TmTable,
    TmAvatar,
  },
})
