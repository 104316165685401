
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import WorkspaceUsersSubAccountFilter from '@/components/modals/admin/workspace/subAccount/WorkspaceUsersSubAccountFilter.vue'
import WorkspaceUsersSubAccountTable from '@/components/modals/admin/workspace/subAccount/WorkspaceUsersSubAccountTable.vue'
import { getTableData } from '@/services/tableService'

export default defineComponent({
  components: {
    WorkspaceUsersSubAccountTable,
    WorkspaceUsersSubAccountFilter,
    TmModal,
  },
  setup() {
    const selected = ref<any[]>([])
    const usersHeaders = ref([
      { text: 'Name', value: 'person' },
      { text: 'Email', value: 'email' },
    ])
    const users = ref(getTableData('contactsAllContacts'))

    return {
      users,
      usersHeaders,
      selected,
    }
  },
})
