import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b0ebdfba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-center justify-between w100pr" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_search = _resolveComponent("tm-search")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_button, {
      color: "primary",
      size: "large",
      disable: !_ctx.selected.length
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Add " + _toDisplayString(_ctx.selected.length) + " users ", 1)
      ]),
      _: 1
    }, 8, ["disable"]),
    _createVNode(_component_tm_search, {
      modelValue: _ctx.search,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
      class: "w240px",
      size: "medium",
      placeholder: "Search sub-accounts"
    }, null, 8, ["modelValue"])
  ]))
}