import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_workspace_users_sub_account_filter = _resolveComponent("workspace-users-sub-account-filter")!
  const _component_workspace_users_sub_account_table = _resolveComponent("workspace-users-sub-account-table")!
  const _component_tm_modal = _resolveComponent("tm-modal")!

  return (_openBlock(), _createBlock(_component_tm_modal, {
    id: "workspaceUsersSubAccount",
    "modal-title": "Add users to sub-account",
    size: "large",
    "no-footer": ""
  }, {
    "modal-content": _withCtx(() => [
      _createVNode(_component_workspace_users_sub_account_filter, {
        selected: _ctx.selected,
        class: "mb-4"
      }, null, 8, ["selected"]),
      _createVNode(_component_workspace_users_sub_account_table, {
        modelValue: _ctx.selected,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
        headers: _ctx.usersHeaders,
        items: _ctx.users,
        "items-name": "sub-accounts",
        "show-selection": ""
      }, null, 8, ["modelValue", "headers", "items"])
    ]),
    _: 1
  }))
}