import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_avatar = _resolveComponent("tm-avatar")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, { "hide-actions": "" }, {
    "body-cell-person": _withCtx((props) => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_tm_avatar, {
          color: props.row.person.avatarColor,
          url: props.row.person.avatar,
          class: "mr-2"
        }, null, 8, ["color", "url"]),
        _createTextVNode(" " + _toDisplayString(props.row.person.fullName), 1)
      ])
    ]),
    _: 1
  }))
}