
import { defineComponent, ref } from 'vue'
import TmSearch from '@/components/shared/TmSearch.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: {
    TmSearch,
    TmButton,
  },
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const search = ref()
    return {
      search,
    }
  },
})
